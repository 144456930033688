import { useState } from "react";
import { useEffect } from "react";
import Storage from "../../services/Storage";
import "./Advert.scss";
// import useDfpSlot from "./useDfpSlots.js";
import { Bling as GPT } from "react-gpt";
import { urlConstants } from "../../utils/urlConstants";
import { useSelector } from "react-redux";
import { Roles } from "../../utils/Roles";

GPT.enableSingleRequest();

function Advert() {
  const { API_URL } = urlConstants;
  const token = Storage.getTokenInCookie("token");

  let userData = useSelector(state => state.UserReducer.userObj);

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  // };

  const adDivId = "ad"
  const [radioValue, setRadioValue] = useState(1);
  const [adPath, setAdPath] = useState({});
  const [showAdv, setShowAdv] = useState(false);
  const [count, setCount] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    showAd();
  }, []);

  useEffect(() => {
    // console.log(count);
    // document.getElementById("myImg").src = images[count];
    setRadioValue(`ad${count+1}`);
    // if(count===0){setAdDivId(adDivId2); setAdPath(adPath2)}
    // else if (count===1){setAdDivId(adDivId1); setAdPath(adPath1)}
    const interval = setInterval(() => {
      // console.log(count);
      // console.log(object.key(adPath).length);
      if (count<2) {
        setCount(count+1);
      } else setCount(0);
    }, 5000);
    return () => clearInterval(interval);
  }, [count]);

  const showAd = () => {
    let googleAddApiUrl = '';
    if (userData?.role === Roles.STUDENT_NAME) {
      googleAddApiUrl = `${API_URL}/api/student/googleads/`;
    } else if (userData?.role === Roles.PARENT_NAME) {
      googleAddApiUrl = `${API_URL}/api/student/googleads/`;
    }
    fetch(googleAddApiUrl, {
      method: "GET",
      headers: {
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (userData?.role === Roles.STUDENT_NAME) {
          // setAdDivId(data?.advdetail[0]?.adv_id);
          console.log("data",data);
          let obj1 = {};
          data?.adv_detail?.forEach((item,index)=>{
            // console.log(item)
            if(item?.status === "active"){
            obj1[index]= item?.path;}
          })
          setAdPath(obj1);
          // console.log("adPath", obj1);
          setShowAdv(true);
        } else if (userData?.role === Roles.PARENT_NAME) {
          // setAdDivId(data?.advdetail?.advdetail[0]?.adv_id);
          let obj2 = {};
          data?.adv_detail?.forEach((item,index)=>{
            console.log(item)
            if(item?.status === "active"){
            obj2[index]= item?.path;}
          })
          setAdPath(obj2);
          setShowAdv(true);
        }
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {showAdv ?
        <div id={adDivId} className='advSlider adSize'>
          <GPT
            adUnitPath={adPath[count]}
            slotSize={[970, 250]}
            sizeMapping={[
              {viewport: [0, 0], slot: [320, 50]},
              {viewport: [750, 0], slot: [970, 250]},
              {viewport: [1050, 0], slot: [970, 250]}
          ]}
          />
        </div>
        : ''}
        <br/>
        {windowWidth <= 750 ? "": (
        <div style={{marginLeft:"380px"}} onChange={(e) => {
          setRadioValue(`ad${e.target.value}`);
          setCount(Number(e.target.value-1));
        }}
        >
        <input type="radio" value="1" checked={radioValue === "ad1"} />
        &emsp;
        <input type="radio" value="2" checked={radioValue === "ad2"} />
        &emsp;
        <input type="radio" value="3" checked={radioValue === "ad3"} />
      </div>)}
    </>
  );
}

export default Advert;