import React, { Fragment, useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import MessageList from "../singlemessage/messageList";
import SingleMessage from "../singlemessage/singleMessage";
import CustomLoader from "../customLoader/CustomLoader";
import ModalExample from "../newMessageModal/NewMessageModal";
import CustomPagination from "../pagination/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import GetMsgService from "../../services/MsgService";
import { useSelector } from "react-redux";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import MsgDataService from "../../services/MsgService";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const Message = () => {
  let hist = useHistory();
  let location = useLocation();
  let studentOrCounselor = location.pathname.split("/")[2];
  console.log("location from the change", location, studentOrCounselor);
  const [msg, setMsg] = useState();
  const [list, setList] = useState();
  const [filterList, setFilterList] = useState();
  const [selectedMessageIndex, setSelectedMsgIndex] = useState();
  const [showSingleMessageComponent, setShowSingleMessageComponent] = useState(
    false
  );
  const [selectedMessage, setSelectedMessage] = useState();
  const [searchMsg, setSearchMsg] = useState();
  //console.log("data for location",location && location.state && location.state.openMessageModal);
  const [open, setOpen] = useState(location["openMessageModal"]);
  const [isStudentOrCounselor, setIsStudentOrCounselor] = useState(
    studentOrCounselor
  );
  const [page, setPage] = useState(1);
  const [msgData, setMsgData] = useState();
  const [loading, setLoading] = useState(false);
  const [NameOrGroupName, setNameOrGroupName] = useState();
  const [
    selectedGroupMessageStudent,
    setSelectedGroupMessageStudent,
  ] = useState(location && location.state);
  const [showNotificationAlert, setShowNotificationAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [numberOfStudents, setNumberOfStudents] = useState(
    location["payloadForSaveResult"]
  );
  const [studentCountData, setStudentCountData] = useState();
  const [internationalCountData, setInternationalCountData] = useState();
  const [counselorCountData, setCounselorCountData] = useState();
  const [selectedUserMsgList, setSelectedUserMsgList] = useState();
  let user = useSelector((state) => state.UserReducer.userObj);

  useEffect(() => {
    async function getSelectedNessageStudent() {
      let array = [];
      if (Array.isArray(selectedGroupMessageStudent)) {
        selectedGroupMessageStudent &&
          selectedGroupMessageStudent.map((item) => {
            let obj = {};
            obj["id"] = item["id"];
            array.push(obj);
          });
        await setNameOrGroupName(array);
      } else if (
        typeof selectedGroupMessageStudent === "string" &&
        selectedGroupMessageStudent === "selected All"
      ) {
        setNameOrGroupName("");
      } else {
        setNameOrGroupName(selectedGroupMessageStudent);
      }
    }

    getSelectedNessageStudent();
  }, [selectedGroupMessageStudent]);

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 3500);
  }, [showSuccessAlert]);

  useEffect(() => {
    setTimeout(() => {
      setShowErrorAlert(false);
    }, 3500);
  }, [showErrorAlert]);

  useEffect(() => {
    {
      showStudentOrCounselor &&
        (user.is_premium || !user.is_premium) &&
        getUnreadCount();
    }
    {
      showInternationalStudent && getUnreadCount();
    }
  }, []);

  const getUnreadCount = () => {
    MsgDataService.getUnreadCountForStudent()
      .then((res) => {
        if (res?.status === 200) {
          const { student, international, counselor } = res?.data || {};
  
          if (student) {
            setStudentCountData(
              Object.values(student).reduce((total, count) => total + count, 0)
            );
          } else {
            setStudentCountData(0);
          }
  
          if (international) {
            setInternationalCountData(
              Object.values(international).reduce((total, count) => total + count, 0)
            );
          } else {
            setInternationalCountData(0);
          }
  
          if (counselor) {
            setCounselorCountData(
              Object.values(counselor).reduce((total, count) => total + count, 0)
            );
          } else {
            setCounselorCountData(0);
          }
        }
      })
      .catch((error) => {
        console.error("error from the service", error);
      });
  };

  const getMsgData = () => {
    setLoading(true);
    let body =
      isStudentOrCounselor === "counselors"
        ? "recruiter_counselor"
        : isStudentOrCounselor === "students"
        ? "recruiter_student"
        : isStudentOrCounselor === "international-students"
        ? "international_student"
        : null;
    GetMsgService.getMsgRecruiterData(page, body)
      .then((data) => {
        setMsgData({ ...data?.data, max: data?.data?.page_count });
        let searchArr = data?.data?.data?.map((item) => item);
        setList(searchArr);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const handleChangeSearchMsg = (e) => {
    setSearchMsg(e.target.value);
    let arr1 = [];
    let filterArr = [];
    if (list) {
      filterArr = list.filter((item, index) => {
        let ans = item?.name
          .toLowerCase()
          .replace(/ +/g, "")
          .match(e?.target?.value?.toLowerCase()?.replace(/ +/g, ""));
        //   ||
        // item?.src_role
        //   .toLowerCase()
        //   .replace(/ +/g, "")
        //   .match(e?.target?.value?.toLowerCase()?.replace(/ +/g, ""));

        if (ans) {
          arr1.push(index);
        }
        return ans;
      });
    }
    let arr2 = arr1.map((item, index) => msgData.data[item]);
    setFilterList(arr2);
  };

  const handleIsStudentOrCounselor = (value) => {
    setIsStudentOrCounselor(value);
    if (value === "students") hist.push("/recruiter/students");
    if (value === "international-students")
      hist.push("/recruiter/international-students");
    if (value === "counselors") hist.push("/recruiter/counselors");
  };

  const closeHandler = () => {
    console.log("getting called from closeHandler");
    setOpen(false);
    setMsg("");
    setNameOrGroupName("");
    setSelectedGroupMessageStudent([]);
  };

  const selectMessage = (item, index) => {
    setSelectedMsgIndex(index);
    setSelectedMessage(item);
    getReadCountbyDate(item.src_id);
    getSelectedContactMessageDetail(item);
    setShowSingleMessageComponent(true);
  };

  const openMessageHandler = () => {
    setOpen(true);
  };
  useEffect(() => {
    setShowSingleMessageComponent(false);
    setSelectedMessage();
    setSelectedMsgIndex();
  }, [isStudentOrCounselor]);

  useEffect(() => {
    {
      showStudentOrCounselor &&
        (user.is_premium || !user.is_premium) &&
        getMsgData();
    }
    {
      showInternationalStudent && getMsgData();
    }
  }, [page]);

  const getSelectedContactMessageDetail = (item) => {
    MsgDataService.getMessageListPerUser(page, item.src_id).then((data) => {
      setSelectedUserMsgList(data.data.messages);
    });
  };

  const getReadCountbyDate = (id) => {
    let payload = {
      src_id: id,
    };
    setLoading(true);
    MsgDataService.readCountDate(payload)
      .then((res) => {
        if (res?.status === 200) {
          console.log("date res", res);
          setLoading(true);
          getUnreadCount();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  let showStudentOrCounselor = false;
  let showInternationalStudent = false;
  let showPremiumMessage = false;
  let summaryType = "STUDENT";
  let newMessagesLabel = "";

  if (
    isStudentOrCounselor === "students" ||
    isStudentOrCounselor === "counselors"
  ) {
    showStudentOrCounselor = true;
    newMessagesLabel =
      isStudentOrCounselor === "counselors"
        ? "NEW COUNSELOR MESSAGES"
        : "NEW STUDENT MESSAGES";
  } else if (isStudentOrCounselor === "international-students") {
    if (user.is_premium) {
      showInternationalStudent = true;
      newMessagesLabel = "NEW INTERNATIONAL STUDENT MESSAGES";
    } else {
      showPremiumMessage = true;
    }
  }

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="main_container">
            {showNotificationAlert && (
              <div
                style={{ padding: "15px 35px" }}
                className="notification_alert"
              >
                <p className="no-margin-bottom">
                  {/* We are sending your messages.. */}
                  Message sending is under process. Once done, you will get a
                  notification in the registered mail.
                </p>
              </div>
            )}
            {showSuccessAlert && (
              <div style={{ padding: "15px 35px" }} className="success_alert">
                <p className="no-margin-bottom">
                  Your Messages have been sent..
                </p>
              </div>
            )}
            {showErrorAlert && (
              <div style={{ padding: "15px 35px" }} className="error_alert">
                <p className="no-margin-bottom">
                  Your Messages were not sent, due to some technical
                  reason.Please Try again after some time!!
                </p>
              </div>
            )}
            <div style={{ padding: "35px", height: "100%" }}>
              <div
                style={{
                  borderRight: "3px solid var(--secondary-color)",
                  borderLeft: "3px solid var(--secondary-color)",
                  width: "100%",
                  height: "100%",
                  background: "white",
                }}
              >
                {loading ? (
                  <div className="loading">
                    {" "}
                    <CustomLoader />{" "}
                  </div>
                ) : (
                  <div>
                    <div className="tab-grid">
                      {/* <button className="inactive-tab inactive-opacity">
                        All Messages
                      </button> */}
                      <button
                        onClick={() => handleIsStudentOrCounselor("students")}
                        className={
                          isStudentOrCounselor === "students"
                            ? "active-tab"
                            : "inactive-tab"
                        }
                      >
                        Students
                      </button>
                      <button
                        onClick={() =>
                          handleIsStudentOrCounselor("international-students")
                        }
                        className={
                          isStudentOrCounselor === "international-students"
                            ? "active-tab"
                            : "inactive-tab"
                        }
                      >
                        International Students
                      </button>
                      <button
                        onClick={() => handleIsStudentOrCounselor("counselors")}
                        className={
                          isStudentOrCounselor === "counselors"
                            ? "active-tab"
                            : "inactive-tab"
                        }
                      >
                        Counselors
                      </button>
                    </div>
                    <div>
                      {showStudentOrCounselor &&
                        (user.is_premium || !user.is_premium) && (
                          <div className="student1Msg">
                            <img
                              style={{ height: "45px", paddingRight: "35px" }}
                              src={IMG_URL + "mail.jpg"}
                            />
                            <div style={{ paddingRight: "25px" }}>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: "var(--text-color)",
                                }}
                              >
                               {isStudentOrCounselor === "students"? 'STUDENTS' : isStudentOrCounselor === "international-students" ? 'International STUDENTS' : 'COUNSELORS'}
                              </p>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: "var(--text-color)",
                                }}
                              >
                                SUMMARY
                              </p>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "20px",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: "30px",
                                  color: "var(--text-color)",
                                }}
                              >
                                {msgData?.total_count}
                              </p>
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: "12px",
                                  color: "var(--text-color)",
                                }}
                              >
                                TOTAL MESSAGES
                              </p>
                            </div>
                            <div className="separator" />
                            <div
                              style={{
                                textAlign: "center",
                                margin: "0px 20px",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: "30px",
                                  color: "var(--text-color)",
                                }}
                              >
                                {newMessagesLabel === "NEW STUDENT MESSAGES"
                                  ? studentCountData || 0
                                  : newMessagesLabel ===
                                    "NEW COUNSELOR MESSAGES"
                                  ? counselorCountData || 0
                                  : newMessagesLabel ===
                                    "NEW INTERNATIONAL STUDENT MESSAGES"
                                  ? internationalCountData || 0
                                  : ""}
                              </p>
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: "12px",
                                  color: "var(--text-color)",
                                }}
                              >
                                {newMessagesLabel}
                              </p>
                            </div>
                            <div className="separator" />
                          </div>
                        )}

                      {showInternationalStudent && (
                        <div className="student1Msg">
                          <img
                            style={{ height: "45px", paddingRight: "35px" }}
                            src={IMG_URL + "mail.jpg"}
                          />
                          <div style={{ paddingRight: "25px" }}>
                            <p
                              style={{
                                marginBottom: "0px",
                                marginTop: "0px",
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "var(--text-color)",
                              }}
                            >
                               {isStudentOrCounselor === "students"? 'STUDENTS' : isStudentOrCounselor === "international-students" ? 'INTERNATIONAL STUDENTS' : 'COUNSELORS'}
                            </p>
                            <p
                              style={{
                                marginBottom: "0px",
                                marginTop: "0px",
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "var(--text-color)",
                              }}
                            >
                              SUMMARY
                            </p>
                          </div>
                          <div
                            style={{ textAlign: "center", marginRight: "20px" }}
                          >
                            <p
                              style={{
                                marginBottom: 0,
                                marginTop: 0,
                                fontSize: "30px",
                                color: "var(--text-color)",
                              }}
                            >
                              {msgData?.total_count}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                marginTop: 0,
                                fontSize: "12px",
                                color: "var(--text-color)",
                              }}
                            >
                              TOTAL MESSAGES
                            </p>
                          </div>
                          <div className="separator" />
                          <div
                            style={{ textAlign: "center", margin: "0px 20px" }}
                          >
                            <p
                              style={{
                                marginBottom: 0,
                                marginTop: 0,
                                fontSize: "30px",
                                color: "var(--text-color)",
                              }}
                            >
                               {newMessagesLabel === "NEW STUDENT MESSAGES"
                                  ? studentCountData || 0
                                  : newMessagesLabel ===
                                    "NEW COUNSELOR MESSAGES"
                                  ? counselorCountData || 0
                                  : newMessagesLabel ===
                                    "NEW INTERNATIONAL STUDENT MESSAGES"
                                  ? internationalCountData || 0
                                  : ""}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                marginTop: 0,
                                fontSize: "12px",
                                color: "var(--text-color)",
                              }}
                            >
                              {newMessagesLabel}
                            </p>
                          </div>
                          <div className="separator" />
                        </div>
                      )}

                      {showPremiumMessage && (
                        <div style={{ margin: "20px 0px 0px 15px" }}>
                          <span>
                            This is for <strong>Premium Users.</strong> For more
                            details please contact to <strong>Admin.</strong>
                          </span>
                          <br />
                          <br />
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className={
                          showStudentOrCounselor ||
                          (showInternationalStudent && user.is_premium)
                            ? "msglist_container"
                            : ""
                        }
                      >
                        {showStudentOrCounselor &&
                          (user.is_premium || !user.is_premium) && (
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "2px solid var(--text-color)",
                                padding: "7px 15px",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                MESSAGES
                              </p>
                              <img
                                onClick={openMessageHandler}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                src={IMG_URL + "SendNewMsgBtn_Default.svg"}
                              />
                            </div>
                          )}

                        {showInternationalStudent && (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderBottom: "2px solid var(--text-color)",
                              padding: "7px 15px",
                            }}
                          >
                            <p
                              style={{ marginBottom: "0px", fontWeight: "600" }}
                            >
                              MESSAGES
                            </p>
                            <img
                              onClick={openMessageHandler}
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                              src={IMG_URL + "SendNewMsgBtn_Default.svg"}
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 15px" }}>
                          {showStudentOrCounselor &&
                            (user.is_premium || !user.is_premium) && (
                              <div
                                style={{ width: "100%" }}
                                className="form-group search-student"
                              >
                                <input
                                  type="text"
                                  className="registration_input"
                                  placeholder="Search Messages"
                                  name="searchMsg"
                                  value={searchMsg}
                                  autoComplete="off"
                                  onChange={handleChangeSearchMsg}
                                  // disabled
                                />
                                <div
                                  style={{
                                    padding: "18px",
                                    border:
                                      "1px solid var(--selected-primary-button-color)",
                                    background:
                                      "var(--selected-primary-button-color)",
                                  }}
                                >
                                  <SearchIcon
                                    style={{
                                      color: "var(--sign-in-text-color)",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                          {showInternationalStudent && (
                            <div
                              style={{ width: "100%" }}
                              className="form-group search-student"
                            >
                              <input
                                type="text"
                                className="registration_input"
                                placeholder="Search Messages"
                                name="searchMsg"
                                value={searchMsg}
                                autoComplete="off"
                                onChange={handleChangeSearchMsg}
                                // disabled
                              />
                              <div
                                style={{
                                  padding: "18px",
                                  border:
                                    "1px solid var(--selected-primary-button-color)",
                                  background:
                                    "var(--selected-primary-button-color)",
                                }}
                              >
                                <SearchIcon
                                  style={{ color: "var(--sign-in-text-color)" }}
                                />
                              </div>
                            </div>
                          )}

                          {msgData && (
                            <>
                              {isStudentOrCounselor === "students" &&
                                (user.is_premium || !user.is_premium) && (
                                  <MessageList
                                    data={msgData && msgData}
                                    selectMessage={selectMessage}
                                    selectedMessageIndex={selectedMessageIndex}
                                    getMsgData={getMsgData}
                                    searchList={filterList}
                                  />
                                )}

                              {isStudentOrCounselor === "counselors" &&
                                (user.is_premium || !user.is_premium) && (
                                  <MessageList
                                    data={msgData && msgData}
                                    selectMessage={selectMessage}
                                    selectedMessageIndex={selectedMessageIndex}
                                    getMsgData={getMsgData}
                                    searchList={filterList}
                                  />
                                )}

                              {isStudentOrCounselor ===
                                "international-students" &&
                                user.is_premium && (
                                  <MessageList
                                    data={msgData && msgData}
                                    selectMessage={selectMessage}
                                    selectedMessageIndex={selectedMessageIndex}
                                    getMsgData={getMsgData}
                                    searchList={filterList}
                                  />
                                )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="single-message-container">
                        {showSingleMessageComponent && (
                          <div>
                            <SingleMessage
                              msgData={selectedMessage && selectedMessage}
                              conversition={
                                selectedUserMsgList && selectedUserMsgList
                              }
                              isStudentOrCounselor={isStudentOrCounselor}
                              setShowSingleMessageComponent={
                                setShowSingleMessageComponent
                              }
                              getMsgData={getMsgData}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showStudentOrCounselor &&
                  (user.is_premium || !user.is_premium) && (
                    <div>
                      <CustomPagination
                        pageValue={page}
                        setPage={setPage}
                        count={msgData}
                      />
                    </div>
                  )}

                {showInternationalStudent && (
                  <div>
                    <CustomPagination
                      pageValue={page}
                      setPage={setPage}
                      count={msgData}
                    />
                  </div>
                )}

                <ModalExample
                  open={open}
                  closeHandler={closeHandler}
                  getMsgData={getMsgData}
                  isStudentOrCounselor={isStudentOrCounselor}
                  NameOrGroupName={NameOrGroupName}
                  setNameOrGroupName={setNameOrGroupName}
                  msg={msg}
                  setMsg={setMsg}
                  setOpen={setOpen}
                  selectedGroupMessageStudent={selectedGroupMessageStudent}
                  setShowSuccessAlert={setShowSuccessAlert}
                  setShowErrorAlert={setShowErrorAlert}
                  setShowNotificationAlert={setShowNotificationAlert}
                  setSelectedGroupMessageStudent={
                    setSelectedGroupMessageStudent
                  }
                  numberOfStudents={numberOfStudents}
                  page={location?.state?.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Message;
