import { useRef, useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  Label,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import PortFolioService from "../../services/PortfolioService";
import Loading from "../Loading/Loading";
import "./Curriculum.scss";
import Switch from "react-switch";
import CommonButton from "../CommonButton/CommonButton";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonMessages } from "../../constants/CommonMessages";
import CurriculumUpload from "./CurriculumUpload";

const Curriculum = () => {
  let userData = useSelector((state) => state.UserReducer.userObj);
  let history = useHistory();
  const datePickerRef = useRef(null);

  const [schoolName, setSchoolName] = useState();
  const [gpa, setGpa] = useState();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(false);

  const [studentValue, setStudentValue] = useState("");
  const [englishValue, setEnglishValue] = useState("");
  const [boardValue, setBoardValue] = useState("");
  const [seminarValue, setSeminarValue] = useState("");
  const [socialValue, setSocialValue] = useState("");
  const [bachelorValue, setBachelorValue] = useState("");
  const [greValue, setGreValue] = useState("");
  const [projectDate, setProjectDate] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);
  const [studentLevel, setStudentLevel] = useState([]);
  const [curriculumData, setCurriculumData] = useState([]);

  useEffect(() => {
    getCurriculum();
  }, []);

  useEffect(() => {
    if (studentValue) {
      localStorage.setItem("studentValue", studentValue);
    }
  }, [studentValue]);

  const getCurriculum = () => {
    setLoading(true);
    PortFolioService.getCurriculum()
      .then((data) => {
        const curriculums = data?.data?.curriculums || [];
        setCurriculumData(curriculums);

        setSchoolName(curriculums?.current_school_name || "");
        setGpa(curriculums?.gpa_score || "");
        setSelectedValue(curriculums?.gpa_scale || 10);
        setStudentValue(curriculums?.desired_degree || "");
        setEnglishValue(curriculums?.english_test || "");
        setProjectDate(parseDate(curriculums?.planned_enrollment_date) || "");
        setSocialValue(curriculums?.social_leadership_activities || "");

        setBoardValue(curriculums?.board_certification || "");
        setSeminarValue(curriculums?.project_work_seminar || "");
        setBachelorValue(curriculums?.relevant_degree || "");
        setGreValue(curriculums?.gre_above_330 || "");

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error from the service", error);
      });
  };

  const handleStudentChange = (e) => {
    setStudentValue(e.target.value);
  };

  const handleEnglishChange = (e) => {
    setEnglishValue(e.target.value);
  };

  const handleBoardChange = (e) => {
    setBoardValue(e.target.value);
  };

  const handleSeminarChange = (e) => {
    setSeminarValue(e.target.value);
  };

  const handleSocialChange = (e) => {
    setSocialValue(e.target.value);
  };

  const handleBachelorChange = (e) => {
    setBachelorValue(e.target.value);
  };

  const handleGREScoreChange = (e) => {
    setGreValue(e.target.value);
  };

  const handleLanguage = (checked) => {
    const selectedLanguage = checked ? "es" : "en";
    setLanguage(checked);
  };

  const parseDate = (dateString) => {
    if (
      !dateString ||
      dateString === "-" ||
      !/^\d{2}-\d{2}-\d{4}$/.test(dateString)
    ) {
      return null;
    }
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error("Invalid date:", date);
      return "";
    }

    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleCurriculumSubmit = () => {
    if (!schoolName) {
      swal("", "Please enter your school name", "error");
      return;
    }
    if (!gpa) {
      swal("", "Please enter your GPA", "error");
      return;
    }
    if (!studentValue) {
      swal("", "Please select desired degree", "error");
      return;
    }

    if (studentValue === "Undergraduate") {
      if (!englishValue) {
        swal("", "Please select english test", "error");
        return;
      }
      if (!projectDate) {
        swal("", "Please select planned enrollment date", "error");
        return;
      }
      if (!socialValue) {
        swal("", "Please select social & leadership activities", "error");
        return;
      }
    }

    if (studentValue === "Graduate") {
      if (!englishValue) {
        swal("", "Please select english test", "error");
        return;
      }
      if (!boardValue) {
        swal("", "Please select board certification", "error");
        return;
      }
      if (!projectDate) {
        swal("", "Please select planned enrollment date", "error");
        return;
      }
      if (!seminarValue) {
        swal("", "Please select project work/seminars", "error");
        return;
      }
      if (!socialValue) {
        swal("", "Please select social & leadership activities", "error");
        return;
      }
      if (!bachelorValue) {
        swal(
          "",
          "Please select 3 to 4 years bachelor's degree in relevant field",
          "error"
        );
        return;
      }
      if (!greValue) {
        swal("", "Please select GRE score of 330 or above", "error");
        return;
      }
    }
    let curriculum = {
      user_id: userData?.id,
      current_school_name: schoolName,
      gpa_score: parseFloat(gpa),
      gpa_scale: parseInt(selectedValue),
      desired_degree: studentValue,
      english_test: englishValue,
      planned_enrollment_date: formatDate(projectDate),
      social_leadership_activities: socialValue,
    };
    if (studentValue === "Graduate") {
      curriculum.board_certification = boardValue;
      curriculum.project_work_seminar = seminarValue;
      curriculum.relevant_degree = bachelorValue;
      curriculum.gre_above_330 = greValue;
    }
    if (studentValue === "Undergraduate") {
      curriculum.board_certification = "";
      curriculum.project_work_seminar = "";
      curriculum.relevant_degree = "";
      curriculum.gre_above_330 = "";
    }
    setLoading(true);
    PortFolioService.saveCurriculum({ curriculum })
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          history.push("/student/curriculum-upload");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error from the service", error);
        const errorMessage =
          error?.response?.data?.error || "Something Went Wrong";
        swal("", errorMessage, "error");
      });
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="title-main">My Curriculum</h1>
            <Switch
              onChange={handleLanguage}
              checked={language}
              width={52}
              height={24}
              handleDiameter={18}
              onColor="#28131A"
              offColor="#049804"
              uncheckedIcon={<div className="switchIncon uncheckIcon">EN</div>}
              checkedIcon={<div className="switchIncon checkIcon">ES</div>}
              className="langSwitch"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mx-auto">
          <Card className="main-card mb-3 interest-main">
            <CardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label for="currentSchoolName" className="labelBold">
                          {language === true
                            ? "Nombre de la escuela actual"
                            : "Current School Name"}
                          *
                        </Label>
                        <Input
                          required
                          type="text"
                          name="school"
                          id="school"
                          placeholder={
                            language === true
                              ? "Ingrese el nombre de su escuela actual"
                              : "Enter your current school name"
                          }
                          value={schoolName}
                          onChange={(e) => setSchoolName(e.target.value)}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <Row>
                        <Col lg="4">
                          <FormGroup className="position-relative">
                            <Label for="gpaScore" className="labelBold">
                              {language === true
                                ? "Puntuación GPA"
                                : "GPA Score"}
                              *
                            </Label>
                            <Input
                              required
                              type="number"
                              name="gpa"
                              id="gpa"
                              placeholder={
                                language === true
                                  ? "Ingrese su promedio"
                                  : "Enter your GPA"
                              }
                              value={gpa}
                              onChange={(e) => setGpa(e.target.value)}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="1" className="d-flex align-items-center mt-4">
                          <span>
                            {language === true ? "Fuera de" : "Out of"}
                          </span>
                        </Col>
                        <Col lg="4 mt-4">
                          <FormGroup className="position-relative">
                            <Input
                              type="select"
                              name="gpaOutOf"
                              id="gpaOutOf"
                              value={selectedValue}
                              onChange={handleDropdownChange}
                            >
                              <option value={4}>4</option>
                              <option value={10}>10</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label
                          className="labelBold"
                          style={{ marginBottom: "20px" }}
                        >
                          {language === true
                            ? "Grado deseado"
                            : "Desired Degree"}
                          *
                        </Label>
                        <div>
                          <FormGroup
                            check
                            inline
                            style={{ marginRight: "50px" }}
                          >
                            <Label check>
                              <Input
                                type="radio"
                                name="desiredDegree"
                                value="Undergraduate"
                                checked={studentValue === "Undergraduate"}
                                onChange={handleStudentChange}
                              />{" "}
                              {language === true
                                ? "De licenciatura"
                                : "Undergraduate"}
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                            style={{ marginRight: "50px" }}
                          >
                            <Label check>
                              <Input
                                type="radio"
                                name="desiredDegree"
                                value="Graduate"
                                checked={studentValue === "Graduate"}
                                onChange={handleStudentChange}
                              />{" "}
                              {language === true ? "Graduado" : "Graduate"}
                            </Label>
                          </FormGroup>
                        </div>
                      </FormGroup>
                    </Col>
                    {studentValue === "Undergraduate" && (
                      <>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Examen de inglés"
                                : "English Test"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="englishTest"
                                    value="Planned"
                                    checked={englishValue === "Planned"}
                                    onChange={handleEnglishChange}
                                  />{" "}
                                  {language === true
                                    ? "Planificado"
                                    : "Planned"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="englishTest"
                                    value="Taken"
                                    checked={englishValue === "Taken"}
                                    onChange={handleEnglishChange}
                                  />{" "}
                                  {language === true ? "Tomado" : "Taken"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Fecha de inscripción planificada"
                                : "Planned Enrollment Date"}
                              *
                            </Label>
                            <br />
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <DatePicker
                                  selected={projectDate}
                                  onChange={(date) => setProjectDate(date)}
                                  dateFormat="yyyy-MM-dd"
                                  minDate={new Date()}
                                  placeholderText={
                                    language
                                      ? "Selecciona la fecha de tu proyecto"
                                      : "Select your project date"
                                  }
                                  className="form-control custom-datepicker"
                                  ref={datePickerRef}
                                />
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "20px",
                                    cursor: "pointer",
                                    color: "#2D3748",
                                  }}
                                  onClick={handleIconClick}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Actividades sociales y de liderazgo"
                                : "Social & Leadership Activities"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="socialActivities"
                                    value="Yes"
                                    checked={socialValue === "Yes"}
                                    onChange={handleSocialChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="socialActivities"
                                    value="No"
                                    checked={socialValue === "No"}
                                    onChange={handleSocialChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )}

                    {studentValue === "Graduate" && (
                      <>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Examen de inglés"
                                : "English Test"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="englishTest"
                                    value="Planned"
                                    checked={englishValue === "Planned"}
                                    onChange={handleEnglishChange}
                                  />{" "}
                                  {language === true
                                    ? "Planificado"
                                    : "Planned"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="englishTest"
                                    value="Taken"
                                    checked={englishValue === "Taken"}
                                    onChange={handleEnglishChange}
                                  />{" "}
                                  {language === true ? "Tomado" : "Taken"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Certificación de la Junta"
                                : "Board Certification"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="boardCertification"
                                    value="Yes"
                                    checked={boardValue === "Yes"}
                                    onChange={handleBoardChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="boardCertification"
                                    value="No"
                                    checked={boardValue === "No"}
                                    onChange={handleBoardChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Fecha de inscripción planificada"
                                : "Planned Enrollment Date"}
                              *
                            </Label>
                            <br />
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <DatePicker
                                  selected={projectDate}
                                  onChange={(date) => setProjectDate(date)}
                                  dateFormat="yyyy-MM-dd"
                                  minDate={new Date()}
                                  placeholderText={
                                    language
                                      ? "Selecciona la fecha de tu proyecto"
                                      : "Select your project date"
                                  }
                                  className="form-control custom-datepicker"
                                  ref={datePickerRef}
                                />
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "20px",
                                    cursor: "pointer",
                                    color: "#2D3748",
                                  }}
                                  onClick={handleIconClick}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Trabajo de proyecto/seminarios"
                                : "Project Work/Seminars"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="projectWork"
                                    value="Yes"
                                    checked={seminarValue === "Yes"}
                                    onChange={handleSeminarChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="projectWork"
                                    value="No"
                                    checked={seminarValue === "No"}
                                    onChange={handleSeminarChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Actividades sociales y de liderazgo"
                                : "Social & Leadership Activities"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="socialActivities"
                                    value="Yes"
                                    checked={socialValue === "Yes"}
                                    onChange={handleSocialChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="socialActivities"
                                    value="No"
                                    checked={socialValue === "No"}
                                    onChange={handleSocialChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Licenciatura de 3 a 4 años en el campo relevante"
                                : "3 to 4 Years Bachelor's Degree in Relevant Field"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="bachelorDegree"
                                    value="Yes"
                                    checked={bachelorValue === "Yes"}
                                    onChange={handleBachelorChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="bachelorDegree"
                                    value="No"
                                    checked={bachelorValue === "No"}
                                    onChange={handleBachelorChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Puntaje GRE de 330 o superior"
                                : "GRE Score of 330 or Above"}
                              *
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="greScore"
                                    value="Yes"
                                    checked={greValue === "Yes"}
                                    onChange={handleGREScoreChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="greScore"
                                    value="No"
                                    checked={greValue === "No"}
                                    onChange={handleGREScoreChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col lg="6">&nbsp;</Col>
                    <Col lg="6">
                      <div className="d-flex justify-content-end btnWrapper">
                        <div className="btnInner">
                          <CommonButton
                            name="Cancel"
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => history.goBack()}
                          />
                        </div>
                        <div className="btnInner">
                          <CommonButton
                            name="Save & Next"
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => handleCurriculumSubmit()}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Curriculum;
