import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const {API_URL} = urlConstants;

const {
  // CONFIGURELIST,
  GET_CURRICULUM,
  GET_CURRICULUM_UPLOAD,
  GET_REFLECTION,
  GET_INTEREST,
  SAVE_INTEREST,
  GET_ACADEMICS,
  GET_ACTIVITIES,
  GET_ATHLETICS,
  SAVE_ACTIVITIES,
  SAVE_ACADEMICS,
  SAVE_ATHLETICS,
  SAVE_CURRICULUM,
  SAVE_CURRICULUM_UPLOAD,

  PARENT_GET_INTEREST,
  PARENT_ACADEMY_DETAIL
} = routes.PORTFOLIO;

export default class PortFolioService {
  static getAcademics(body) {
    return request(
      "GET",
      `${API_URL}${GET_ACADEMICS}`,
      null,
      body,
      null,
      null
    );
  }
  static getActivities(body) {
    return request(
      "GET",
      `${API_URL}${GET_ACTIVITIES}`,
      null,
      body,
      null,
      null
    );
  }
  static getAthletics(body) {
    return request(
      "GET",
      `${API_URL}${GET_ATHLETICS}`,
      null,
      body,
      null,
      null
    );
  }

  // static getConfigureList() {
  //   return request("GET", `${API_URL}${CONFIGURELIST}`, null, null, null, null);
  // }
  static saveActivities(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_ACTIVITIES}`,
      null,
      body,
      null,
      null
    );
  }
  static saveAcademics(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_ACADEMICS}`,
      null,
      body,
      null,
      null
    );
  }
  static saveAthletics(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_ATHLETICS}`,
      null,
      body,
      null,
      null
    );
  }
  static getInterest() {
    return request("GET", `${API_URL}${GET_INTEREST}`, null, null, null, null);
  }
  static saveInterest(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_INTEREST}`,
      null,
      body,
      null,
      null
    );
  }

  static getCurriculum() {
    return request("GET", `${API_URL}${GET_CURRICULUM}`, null, null, null, null);
  }

  static getReflection() {
    return request("GET", `${API_URL}${GET_REFLECTION}`, null, null, null, null);
  }

  static getParentReflection(obj) {
    console.log("op",obj);
    return request("GET", `${API_URL}${GET_REFLECTION}?role=parent&student_id=${obj.student_id}`, null, null, null, null);
  }
  

  static getCurriculumUpload() {
    return request("GET", `${API_URL}${GET_CURRICULUM_UPLOAD}`, null, null, null, null);
  }

  static saveCurriculum(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_CURRICULUM}`,
      null,
      body,
      null,
      null
    );
  }
  static saveCurriculumUpload(body) {
    return request(
      "POST",
      `${API_URL}${SAVE_CURRICULUM_UPLOAD}`,
      null,
      body,
      null,
      null,
    );
  }
  static saveInterest(body) {
    return request(
      "PUT",
      `${API_URL}${SAVE_INTEREST}`,
      null,
      body,
      null,
      null
    );
  }

  // Parent Get Interest
  static getParentRoleInterest(queryValue){
    console.log("queryVaLUE",queryValue);
    let {student_id} = queryValue;
    return request("GET", `${API_URL}${PARENT_GET_INTEREST}?student_id=${student_id}`, null, null, null, null);
  }

  static getParentRoleAcademy(queryValue){
    let {student_id} = queryValue;
    let qString = `?student_id=${student_id}`
    return request("GET", `${API_URL}${PARENT_ACADEMY_DETAIL}${qString}`, null, null, null, null);
  }
}
