import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Input, Label, Tooltip } from "reactstrap";
import { CommonButton } from "../../components";
import Loading from "../../components/Loading/Loading";
import RegisterService from "../../services/RegisterService";
import "./CreateStudentAccount.scss";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { CommonMessages } from "../../constants/CommonMessages";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Diversity1 } from "@mui/icons-material";

const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

// is_middle_school : false => higherSchoolData
const gradeData = [
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "Alumni", value: "Alumni" },
  { name: "No Grade", value: "No Grade" },
];
const higherSchoolData = [
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "Alumni", value: "Alumni" },
  { name: "No Grade", value: "No Grade" },
];

const CreateStudentAccount = () => {
  const history = useHistory();
  const datePickerRef = useRef(null);

  const initState = {
    schoolName: "",
    firstName: "",
    lastname: "",
    stdId: "",
    gender: "",
    birthDate: "",
    currentGrade: "",
    schoolEmail: "",
    password: "",
    confirmPassword: "",
    mobileNo: "",
    parentEmail: "",
    countryName: "",
    state: "",
    selectedState: "",
  };
  const [stdForm, setStdForm] = useState(initState);
  const [show, setShow] = useState(false);
  const [schoolArr, setSchoolArr] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [counstData, setCounstData] = useState([]);

  const [agreeCheck, setAgreeCheck] = useState(true);
  const [firstAttendCheck, setFirstAttendCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPswd, setShowPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false);

  // Next click state
  const [detailArr, setDetailArr] = useState({});
  const [gradeArr, setGradeArr] = useState([]);
  //   const [countryName, setCountryName] = useState("");
  const [states, setStates] = useState([]);
  //   const [state, setState] = useState("");
  //   const [selectedState, setSelectedState] = useState("");
  const [showCountryContainer, setShowCountryContainer] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const countryRef = useRef();
  const timeoutRef = useRef();

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const parseDate = (dateString) => {
    if (
      !dateString ||
      dateString === "-" ||
      !/^\d{2}-\d{2}-\d{4}$/.test(dateString)
    ) {
      return null;
    }
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  const handleBirthChange = (value, fieldName) => {
    setStdForm(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  };
  

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const formatDate = (date) => {
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const lowercasedValue =
      name === "parentEmail" ? value.toLowerCase() : value;
    setStdForm({ ...stdForm, [name]: lowercasedValue });

    if (name === "schoolName") {
      // setStdForm({ ...stdForm, schoolName: value, firstName: '', lastname: '', stdId: '',currentGrade: '' });
      if (value !== "" && value.length >= 3) {
        setLoading(false);
        let query = {
          q: value,
        };
        RegisterService.getSchoolNameList(query)
          .then((data) => {
            setSchoolArr(data?.data?.objects);

            let schoolData = data?.data?.objects || [];

            if (schoolData && schoolData.length > 0) {
              let selectedSchoolData =
                schoolData.filter(
                  (schlData) =>
                    schlData.name.toLowerCase().trim() ===
                    value.toLowerCase().trim()
                ) || [];
              setSelectedSchool(selectedSchoolData);

              // set Grade Array
              if (selectedSchoolData && selectedSchoolData.length > 0) {
                if (selectedSchoolData[0].is_middle_school === false) {
                  setGradeArr(higherSchoolData);
                } else {
                  setGradeArr(gradeData);
                }
              } else {
                setGradeArr([]);
                setCounstData([]);
                setShow(false);
              }
            }

            setLoading(false);
          })
          .catch((error) => {
            console.log("error from the service", error);
            setLoading(false);
          });
      } else {
        setSchoolArr([]);
        setSelectedSchool([]);
        setGradeArr([]);
        setCounstData([]);
        setShow(false);
      }
    }
  };

  const handleChangeAgreeCheck = (e) => {
    setAgreeCheck(false);
  };

  const handleChangeFirstAttend = (e) => {
    setFirstAttendCheck(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Next Click
    let userData;
    if (!show) {
      if (selectedSchool.length === 0) {
        swal("", CommonMessages.schoolNameMatchError, "error");
      } else if (selectedSchool && selectedSchool.length > 0) {
        setLoading(true);
        let obj = {
          // cleverid: '',
          first_name: stdForm.firstName,
          last_name: stdForm.lastname,
          school_id: selectedSchool[0].id || "",
          // source_id: '',
          student_id: stdForm.stdId,
        };
        RegisterService.checkStudentDetail(obj)
          .then((res) => {
            if (res.status === 200) {
              swal({
                text: res?.data?.status,
                icon: "error",
                buttons: {
                  OK: true,
                },
                closeOnClickOutside: false,
              }).then((value) => {
                if (value === "OK") {
                  history.push("/login");
                }
              });
            } else if (res.status === 201 && res.data?.flag === 1) {
              swal("", CommonMessages.userDetailMatchSuccess, "success");

              userData = res?.data?.user_id || {};
              // setFirstAttendCheck(userData?.student_first_gen)
              // setAgreeCheck(userData?.student_career_opt_in)
              setFirstAttendCheck(res?.data?.user_id?.student_first_gen);
              setAgreeCheck(res?.data?.user_id?.student_career_opt_in);
              setStdForm((prev) => {
                // const CounselorName = counstArr[0]?.counselor?.name
                return {
                  ...prev,
                  firstName: userData?.first_name,
                  lastname: userData?.last_name,
                  stdId: userData?.student_id,
                  currentGrade: userData?.current_grade,
                  gender: userData?.gender,
                  birthDate: parseDate(userData?.birth_date),
                  counselor: userData?.counselor_name,
                  schoolEmail: userData?.email,
                  mobileNo: userData?.mobile,
                  password: "",
                  confirmPassword: "",
                  parentEmail: userData?.parent_email,
                  counselorId: userData?.counselor_id,
                  parentMobile: userData?.parent_mobile,
                  role: userData?.role,
                  countryName: userData?.country,
                  state: userData?.state,
                  // group: userData?.group,
                };
              });

              setDetailArr(res?.data);
              setShow(true);

              // let counstArr;
              // if (userData) {

              // Get Counst APi Call after getting data from userdetail
              // let counstPayload = {
              //     role: userData.role || '',
              //     school: userData.school_id || '',
              //     student: userData.id || '',
              // }
              // RegisterService.getRegisterCounst(counstPayload).then(data => {
              // if (userData && userData.length > 0) {
              //     // counstArr = data?.data?.objects;
              //     // setCounstData(counstArr);

              // } else {
              //     setCounstData([]);
              // }
              // }
              // )
              //     .catch((error) => {
              //             console.log("error from the service", error);
              //             setLoading(false);
              //         });

              //         setShow(true);
              //     }
              // }
              // }
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log("error from the service", error);
            setLoading(false);
          });
      }
    }

    if (show) {
      if (stdForm.password !== stdForm.confirmPassword) {
        swal("", CommonMessages.passwordMatchError, "error");
      } else if (selectedSchool) {
        let counstFlag = [];
        if (detailArr) {
          console.log("data", detailArr?.user_id?.map_method);
          if (detailArr?.user_id?.map_method === "manual") {
            counstFlag = [3];
          } else if (detailArr?.user_id?.map_method === "groupname") {
            counstFlag = [2];
          } else if (detailArr?.user_id?.map_method === "caseload") {
            counstFlag = [1];
          }
        }
        setLoading(true);
        let createStudentObj = {
          counselor: [stdForm.counselorId] || [],
          current_grade: stdForm.currentGrade || "",
          email: stdForm.schoolEmail || "",
          first_name: stdForm.firstName || "",
          flag: counstFlag,
          country: stdForm.countryName,
          state: stdForm.selectedState || stdForm.state,
          // group_id: stdForm.group || null,
          last_name: stdForm.lastname || "",
          gender: stdForm.gender,
          birth_date: formatDate(stdForm.birthDate || null),
          mobile: stdForm.mobileNo || "",
          parent_email: stdForm.parentEmail || "",
          parent_mobile: stdForm.parentMobile || null,
          password: stdForm.password || "",
          confirm_password: stdForm.confirmPassword || "",

          // school: selectedSchool && selectedSchool.length > 0 ? selectedSchool[0].id : '',
          // school_codename: selectedSchool && selectedSchool.length > 0 ? selectedSchool[0].codename : '',
          school_id:
            selectedSchool && selectedSchool.length > 0
              ? selectedSchool[0].id
              : "",
          // school_name: stdForm.schoolName || '',
          school_upload:
            selectedSchool && selectedSchool.length > 0
              ? selectedSchool[0].upload_csv
              : false,

          student_first_gen: firstAttendCheck || false,
          // student_id: detailArr?.user_id?.student_id || null, // user detail id
          student_career_opt_in: agreeCheck,
          user_ptr_id: detailArr?.user_id?.user_ptr_id || "",
          type: stdForm?.role, // "student"
        };

        RegisterService.createStudentAccount(createStudentObj)
          .then((res) => {
            if (res?.status === 200) {
              swal("", CommonMessages.studentRegSuccess, "success");
              setTimeout(() => {
                history.push("/login");
              }, 3000);
            } else {
              swal(" ", "error to register", "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            swal("", error?.response?.data?.message, "error");
            setLoading(false);
          });
      }
    }
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setStdForm({ ...stdForm, countryName: value });
    clearTimeout(timeoutRef.current);
    if (!value) {
      setShowCountryContainer(false);
      setCountryList([]);
      setSelectedCountry(null);
      setStdForm({
        ...stdForm,
        selectedState: "",
        state: "",
        countryName: value,
      });
    } else {
      setStdForm({ ...stdForm, countryName: value });
      timeoutRef.current = setTimeout(() => {
        if (value !== "" && value.length >= 3) {
          setShowCountryContainer(true);
          setLoading(false);
          let query = {
            q: value,
          };
          RegisterService.getCountryDataList(query)
            .then((res) => {
              setCountryList(res?.data?.country);
              // const countryNames = res?.data?.country.map(
              //   (country) => country?.name
              // );
              // console.log("ju",countryNames)
              // Update the state with the array of country names
              // setStdForm({ ...stdForm, countryName: selectedCountry.name });
            })
            .catch((error) => {
              console.log("error from the service", error);
              setLoading(false);
            });
        }
      });
    }
  };

  useEffect(() => {
    if (selectedCountry && selectedCountry.name === "United States") {
      setLoading(true);
      RegisterService.getStateDataList(selectedCountry.name)
        .then((res) => {
          setStates(res?.data?.state);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching states for United States:", error);
          setLoading(false);
        });
    }
  }, [selectedCountry]);

  const handleStateChange = (e) => {
    const { value } = e.target;
    setStdForm({ ...stdForm, state: value, selectedState: value });
    // setStdForm({ ...stdForm, });
  };

  const handleCountrySelection = (selectedCountry) => {
    setShowCountryContainer(false);
    setStdForm({ ...stdForm, countryName: selectedCountry.name });
    setSelectedCountry(selectedCountry);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="studentAccountWrap">
        <div className="studentAccountInner text-center">
          <img
            src={IMG_URL + "white-logo.png"}
            alt="whiteLogo"
            className="logoImg img-fluid"
          />
          <div className="studentAccountBlockwrap">
            <div className="studentAccountBlockInner">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="boxWrap">
                  <div className="boxwrapInner">
                    <h2 className="accountTitle text-center">
                      Create your student connect account
                    </h2>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        School Name <span className="requiredField">*</span>
                      </Label>
                      <input
                        required
                        placeholder="Enter your school name"
                        className="form-control custom-select"
                        type="text"
                        list="listOfSchool"
                        name="schoolName"
                        value={stdForm.schoolName || ""}
                        onChange={handleChange}
                      />
                      <datalist id="listOfSchool" className="col-12">
                        {schoolArr && schoolArr.length > 0
                          ? schoolArr.map((item, index) => {
                              return <option value={item.name} key={index} />;
                            })
                          : ""}
                      </datalist>
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        First Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your first name"
                        type="text"
                        name="firstName"
                        value={stdForm.firstName || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Last Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your last name"
                        type="text"
                        name="lastname"
                        value={stdForm.lastname || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {/* {show ?
                                            <FormGroup className='text-left'>
                                                <Label className='customLabel'>Middle Name </Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='Enter your middle name'
                                                    type='text'
                                                    name='middlename'
                                                    value={stdForm.middlename || ''}
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                            : ''} */}
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Student ID <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your student Id"
                        type="text"
                        name="stdId"
                        value={stdForm.stdId || ""}
                        onChange={handleChange}
                        // onKeyPress={(e) => {
                        //     if (!/[0-9]/.test(e.key)) {
                        //         e.preventDefault();
                        //     }
                        // }}
                      />
                    </FormGroup>
                    {show ? (
                      <>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Gender <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            type="select"
                            name="gender"
                            value={stdForm.gender}
                            onChange={handleChange}
                          >
                            <option value={""}>Select gender</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                            <option value={"Non-Binary"}>Non-Binary</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label for="birthdate" className="custom-lable">
                            Birthdate <span className="requiredField">*</span>
                          </Label>
                          <br />
                          <div>
                            <div style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}>
                              <DatePicker
                                selected={stdForm.birthDate}
                                onChange={(date) => handleBirthChange(date, "birthDate")}
                                dateFormat="yyyy-MM-dd"
                                minDate={new Date(1900, 0, 1)}
                                maxDate={
                                  new Date(new Date().getFullYear() - 5, 11, 31)
                                }
                                placeholderText={"Select your birthdate"}
                                className="form-control custom-datepicker"
                                ref={datePickerRef}
                                required
                              />
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  width: "20px",
                                  cursor: "pointer",
                                  color: "#2D3748",
                                }}
                                onClick={handleIconClick}
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Current Grade{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            type="select"
                            name="currentGrade"
                            value={stdForm.currentGrade || ""}
                            onChange={handleChange}
                          >
                            <option value={""}>
                              Select your current grade
                            </option>
                            {gradeArr &&
                              gradeArr.length > 0 &&
                              gradeArr.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Input>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label for="country">
                            Country <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your country name"
                            type="text"
                            name="country"
                            ref={countryRef}
                            list="listOfCountry"
                            value={stdForm.countryName || ""}
                            onChange={handleCountryChange}
                            autoComplete="off"
                          />
                          {showCountryContainer ? (
                            <div>
                              <ul className="admin-school-name-list">
                                {countryList &&
                                  countryList.length > 0 &&
                                  countryList.map((item, index) => (
                                    <li
                                      onClick={() =>
                                        handleCountrySelection(item)
                                      }
                                      key={index}
                                      className="flex admin-school-list cursor"
                                    >
                                      <span>{item.name}</span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        {stdForm.countryName && (
                          <FormGroup className="text-left">
                            <Label for="state">
                              State <span className="requiredField">*</span>
                            </Label>
                            {selectedCountry &&
                            selectedCountry.name === "United States" ? (
                              <Input
                                required
                                className="form-control"
                                type="select"
                                name="state"
                                value={stdForm.selectedState || ""}
                                onChange={handleStateChange}
                              >
                                <option value="" disabled selected>
                                  Select your state name
                                </option>
                                {states.map((stat) => (
                                  <option key={stat.name} value={stat.name}>
                                    {stat.name}
                                  </option>
                                ))}
                              </Input>
                            ) : (
                              <Input
                                required
                                className="form-control"
                                placeholder="Enter your state name"
                                type="text"
                                name="state"
                                value={stdForm.state || ""}
                                onChange={handleStateChange}
                              />
                            )}
                          </FormGroup>
                        )}
                        {/* <FormGroup className='text-left'>
                                                    <Label className='customLabel'>Group</Label>
                                                    <Input
                                                        className="form-control"
                                                        type='select'
                                                        name='group'
                                                        value={stdForm.group|| ''}
                                                        onChange={handleChange}
                                                        disabled
                                                    >
                                                    <option value={''}>Select Group</option>
                                                    </Input>
                                                </FormGroup> */}
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Counselor <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your counselor name"
                            type="text"
                            name="counselorName"
                            value={stdForm.counselor || ""}
                            onChange={handleChange}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            School Email Address{" "}
                            <span className="requiredField">*</span>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              id="TooltipSchoolEmail"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </Label>
                          <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target="TooltipSchoolEmail"
                            toggle={toggleTooltip}
                          >
                            Enter student email ID provided by school
                          </Tooltip>
                          <Input
                            required
                            // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            disabled
                            className="form-control"
                            placeholder="Enter your school email address"
                            type="email"
                            name="schoolEmail"
                            value={stdForm.schoolEmail || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Password <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your password"
                            type={showPswd ? "text" : "password"}
                            name="password"
                            minLength="8"
                            value={stdForm.password || ""}
                            onChange={handleChange}
                          />
                          <FontAwesomeIcon
                            icon={showPswd ? faEye : faEyeSlash}
                            onClick={() => setShowPswd(!showPswd)}
                            style={{
                              position: "absolute",
                              right: "13px",
                              top: "41px",
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Confirm Password{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Re-enter your password"
                            type={showconfirmPswd ? "text" : "password"}
                            name="confirmPassword"
                            minLength="8"
                            value={stdForm.confirmPassword || ""}
                            onChange={handleChange}
                          />
                          <FontAwesomeIcon
                            icon={showconfirmPswd ? faEye : faEyeSlash}
                            onClick={() => setShowconfirmPswd(!showconfirmPswd)}
                            style={{
                              position: "absolute",
                              right: "13px",
                              top: "41px",
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">Mobile Number</Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your mobile number"
                            type="text"
                            name="mobileNo"
                            value={stdForm.mobileNo || ""}
                            onChange={handleChange}
                            // onKeyPress={(e) => {
                            //     if (!/[0-9]/.test(e.key)) {
                            //         e.preventDefault();
                            //     }
                            // }}
                          />
                          <p className="hintText">Hint: +13238511115</p>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Parent Email{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            className="form-control"
                            placeholder="Enter your parent email"
                            type="email"
                            name="parentEmail"
                            value={stdForm.parentEmail || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup
                          check
                          className="text-left mt-4 mb-2 customCheckbox"
                        >
                          <Label check>
                            <Input
                              type="checkbox"
                              name="firstAttendCheck"
                              checked={firstAttendCheck}
                              onChange={handleChangeFirstAttend}
                            />
                            I would be the first in my family to attend college.
                          </Label>
                        </FormGroup>

                        <FormGroup check className="text-left customCheckbox">
                          <Label check>
                            <Input
                              type="checkbox"
                              name="agreeCheck"
                              checked={agreeCheck}
                              onChange={() => setAgreeCheck(!agreeCheck)}
                            />
                            I agree to share my goals and academic progress with
                            college admissions professionals to help me to
                            college for a career and employment. I would like
                            their support to my academic and career goals
                            through SMS communication.
                          </Label>
                        </FormGroup>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="btnWrap">
                    <div className="btnWrapper">
                      <div className="btnInner">
                        <CommonButton
                          name="Cancel"
                          btnClass="secondaryBtn w-100 stdFormBtn"
                          onClick={() => {
                            if (show) {
                              setShow(false);
                            } else {
                              history.goBack();
                            }
                          }}
                        />
                      </div>
                      {show ? (
                        <div className="btnInner">
                          <CommonButton
                            type="submit"
                            name="Create your account"
                            btnClass="primaryBtn w-100 stdFormBtn"
                            //   onClick={}
                          />
                        </div>
                      ) : (
                        <div className="btnInner">
                          <CommonButton
                            type="submit"
                            name="Next"
                            btnClass="primaryBtn w-100 stdFormBtn"
                            //   onClick={}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudentAccount;